//color
$clr-1: #3DA2BF;
$clr-2: #F0F0F0;
$clr-3: #DCDCDC;
$clr-4: #464646;
$clr-5: #94C631;

@mixin mobile($mobile:1140px) {
  @media screen and (max-width: $mobile) {
    @content; } }

@mixin tablet($tablet:767px) {
  @media screen and (max-width: $tablet) {
    @content; } }

@mixin phone($phone:640px) {
  @media screen and (max-width: $phone) {
    @content; } }

@mixin mini($min:500px) {
  @media screen and (max-width: $min) {
    @content; } }

@mixin max($size) {
  @media screen and (max-width: $size) {
    @content; } }

@mixin min($size) {
  @media screen and (min-width: $size) {
    @content; } }

//mixin
@mixin size($w,$h:$w) {
  width: $w;
  height: $h; }

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin t($t:0.5) {
  transition-duration: #{$t}s; }

@mixin clr($c:#222) {
  color: $c; }

@mixin font($size:16px,$color:#000,$space:1.6px) {
  font-size: $size;
  color: $color;
  letter-spacing: $space;
  @content;
  @include phone {
    font-size: calc(#{$size} * 0.9);
    letter-spacing: calc(#{$space} * 0.9); }
  @include mini {
    font-size: calc(#{$size} * 0.75);
    letter-spacing: calc(#{$space} * 0.75); } }


@mixin container {
  display: block;
  padding: 0;
  max-width: 1040px;
  width: calc(100% - 120px);
  @include min(1360px) {
    max-width: 1200px; }
  @include mobile {
    width: calc(100% - 60px); }

  @content; }

@mixin more {
  display: table;
  padding: 12px 25px 12px 20px;
  border: solid 1px $clr-5;
  text-decoration: none;
  @include font(12px,$clr-5,.5px);
  @include t(.3);
  &:after {
    content: ">";
    margin-left: 10px; }
  &:hover {
    background-color: $clr-5;
    color: #fff; } }
