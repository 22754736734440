// 引入
@import "./_include/_include.sass";

#idx-carousel {
  &>.kc-row-container {
    visibility: hidden; }

  .swiper-slide {
    position: relative;
    h2 {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 28%;
      color: #fff;
      text-align: center;
      @include font(48px,#fff,3.6px);
      opacity: 0;
      transition-delay: 1s;
      @include t(1);
      @include tablet {
        @include font(30px,#fff,2.6px);
        bottom: 45%; }
      span {
        display: block;
        margin-top: 15px;
        font-weight: normal;
        @include font(18px,#fff,1.35px);
        text-transform: capitalize;
        @include tablet {
          margin-top: 10px; } } }
    &.swiper-slide-active {
      h2 {
        opacity: 1; } } }

  img {
    display: block;
    @include size(100%,auto); }

  .scroll-down {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    z-index: 2;
    @include font(16px,$clr-2,1.2px);
    &:after {
      content: "";
      display: block;
      margin: 10px auto 0;
      @include size(1px,48px);
      background-color: #F0F0F0; } } }

#idx-info {
  padding: 70px 0;
  position: relative;
  &:after,&:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-repeat: no-repeat; }
  &:before {
    background-image: url(/wp-content/uploads/bg_1.svg);
    background-position: 120% 20%;
    background-size: 40%; }
  &:after {
    background-image: url(/wp-content/uploads/bg_2.svg);
    background-position: -10% 100%;
    background-size: 40%; }
  &>div {
    position: relative;
    z-index: 2;
    @include container; }
  .kc_row.kc_row_inner {
    display: flex;
    align-items: center;
    margin-bottom: 65px;
    @include tablet {
      display: block; }
    &:last-of-type {
      margin-bottom: 0; }
    .kc_text_block {
      position: relative;
      padding-right: 50px;
      min-height: 250px;
      @include tablet {
        padding-left: 50px; }
      * {
        position: relative;
        z-index: 2; }
      h2 {
        @include font(26px,$clr-4,2px); }
      h3 {
        margin: 5px 0;
        @include font(14px,$clr-5,1px);
        text-transform: capitalize; }
      h4 {
        margin: 20px 0;
        line-height: 28px;
        @include font(18px,$clr-4,1px); }
      p {
        margin: 20px 0;
        @include font(14px,$clr-4,1px);
        &:last-of-type {
          margin-bottom: 0; }
        a {
          @include more; } }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: -25px;
        right: 0px;
        width: calc(100% + 50px);
        height: calc(100% + 50px);
        box-shadow: 0px 0px 11px #00000029;
        background-color: #fff;
        @include tablet {
          width: 100%; } } }


    .kc_single_image {
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        @include t(2); }
      &:hover {
        img {
          transform: scale(1.1); } } }

    &:nth-of-type(odd) {
      flex-direction: row-reverse;
      .kc_text_block {
        padding-right: 0;
        padding-left: 50px;
        @include tablet {
          padding-right: 50px; }
        &:after {
          left: 0;
          right: auto; } } } } }

#idx-product {
  background-image: url(/wp-content/uploads/bg_3.png);
  background-size: cover;
  background-position: center bottom; }

#idx-product,#idx-performance {
  padding-top: 50px;
  padding-bottom: 70px;
  &>div {
    @include container;
    &>.kc-wrap-columns {
      margin-left: -10px;
      width: calc(100% + 20px); } }

  img {
    display: block;
    @include size(100%,auto); }

  .kc_text_block {
    padding: 0 10px;
    &>* {
      overflow: hidden; }
    img {
      @include t(1.5); }
    &:hover {
      img {
        transform: scale(1.2); } } }


  .kc_text_block:not(.title) {
    position: relative;
    @include tablet {
      margin-bottom: 30px; }
    h3 {
      position: absolute;
      bottom: 0;
      left: 10px;
      width: calc(100% - 20px);
      pointer-events: none;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        backdrop-filter: blur(13px);
        background: rgba(#6d8a1d,.7); }
      a {
        display: block;
        padding: 30px 0;
        position: relative;
        z-index: 2;
        @include font(20px,#fff,4px); } } } }

.top {
  margin: 60px auto -40px;
  transform: rotate(180deg);
  background-image: url(/wp-content/uploads/down-arrow.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include size(30px,15px);
  cursor: pointer; }


.float-links {
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 2;
  @include phone {
    right: 5px; }
  li {
    margin-bottom: 15px;
    list-style-type: none;
    a,img,svg {
      display: block;
      @include size(45px); }
    svg {
      ellipse {
        @include t(.5); } }
    a:hover {
      svg {
        ellipse {
          fill: #94C631; } } } } }
