// 引入
@import "./_include/_include.sass";

.page-header.page--cover {
  padding: 0;
  &:before {
    display: none; }
  &:after {
    content: "";
    display: block;
    padding-bottom: 40%; }
  .container {
    position: absolute;
    left: 0;
    bottom: 45%;
    transform: translateY(50%);
    width: 100%;
    max-width: 100%; }
  h1.entry-title {
    display: flex;
    align-items: center;
    justify-content: center;
    @include font(26px,#fff,2px);
    &:after,&:before {
      content: "";
      display: block;
      margin: 0 20px;
      border-top: solid 1px #fff;
      @include size(70px,0); } }
  p {
    font-style: normal;
    font-weight: bold;
    @include font(16px,#fff,1px); } }

body.archive.category {
  .page-header.page--cover {
    h1.entry-title {
      font-size: 0;
      span {
        @include font(26px,#fff,2px); } } }
  #content-inside {
    background-image: url(/wp-content/uploads/bg01.png);
    background-size: 100% auto;
    background-position: top center;
    .container {
      display: flex;
      flex-wrap: wrap;
      padding: 60px 0 100px;
      @include tablet {
        flex-direction: column;
        padding: 30px 0 60px; } } } }

body.archive.category {
  #secondary {
    padding: 0;
    width: 20%;
    @include tablet {
      width: 100%; } }
  #primary {
    width: 80%;
    @include tablet {
      width: 100%; } }
  .widget-title {
    display: none; }
  .widget_categories {
    margin: 0;
    @include tablet {
      margin-bottom: 30px; }
    ul {
      margin: 0;
      @include tablet {
        display: flex;
        justify-content: center;
        flex-wrap: wrap; }
      li {
        margin: 0 0 15px;
        width: 100%;
        float: none;
        @include tablet {
          margin: 0 10px;
          width: fit-content; }
        a {
          @include font(18px,#000,1px);
          line-height: normal; }
        &.current-cat {
          a {
            color: #07B2E3;
            text-decoration: underline; } } } } }
  .post {
    background-color: #fff;
    margin-bottom: 45px;
    padding: 20px 45px 30px!important;
    border-top: 14px solid rgba(#94C631,.5)!important;
    border-bottom: none;
    box-shadow: 0px 0px 8px #00000029;
    @include tablet {
      padding: 20px 30px!important; }
    .entry-header {
      padding-bottom: 15px;
      margin-bottom: 20px;
      border-bottom: solid 2px #959595; }
    .entry-title {
      @include font(22px,#000,1px);
      font-weight: bold; }
    .date {
      @include font(16px,#94C631,1px);
      font-weight: bold; }
    .entry-excerpt {
      @include font(18px,#000,1.5px);
      a.read-more {
        display: block;
        margin-top: 5px;
        float: right;
        @include font(16px,#464646,1px);
        text-decoration: none;
        span {
          margin-left: 5px; } } } }
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    @include font(16px,#464646,1px); } }

body.single-post {
  #content-inside {
    background-image: url(/wp-content/uploads/bg01.png);
    background-size: 100% auto;
    background-position: top center; }
  #primary {
    @include container;
    padding: 60px 0 100px;
    margin: 0 auto;
    float: none;
    @include tablet {
      padding: 30px 0 60px; } }
  .entry-header {
    margin-bottom: 40px;
    @include tablet {
      margin-bottom: 20px; }
    .entry-title {
      @include font(22px,#000,1px);
      font-weight: bold; }
    .date {
      @include font(16px,#94C631,1px);
      font-weight: bold; } }
  .entry-meta {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none; }
  .entry-content {
    p {
      padding-bottom: 30px;
      @include font(18px,#000,1.5px);
      @include tablet {
        padding-bottom: 20px; } } } }

[id^="module-"] {
  padding: 60px 0 100px;
  // background-repeat: no-repeat
  background-size: 100% auto;
  background-position: top center;
  @include tablet {
    padding: 30px 0 60px; } }

.kc-video-inner {
  position: relative;
  padding-bottom: 56.5%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%!important); } }

[id^="module-about"] {
  background-image: url(/wp-content/uploads/bg01.png);
  &>div {
    @include container;
    @include clearfix; }
  .kc_row_inner {
    margin-bottom: 15px;
    @include tablet {
      margin-bottom: 5px; } }

  .kc_text_block {
    h3 {
      margin-bottom: 15px;
      @include font(18px,$clr-5,1px); }
    p {
      padding-bottom: 30px;
      @include font(18px,#000,1px); } } }

[id^="module-product"] {
  background-image: url(/wp-content/uploads/bg02.png);
  &>div {
    @include container;
    @include clearfix; }

  .pagecounts {
    padding-left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      padding-left: 0; }
    .motion {
      margin: 0 7px;
      border-top: solid 8px transparent;
      border-bottom: solid 8px transparent;
      @include size(0);
      cursor: pointer;
      &.prev {
        border-right: solid 12px #94C631; }
      &.next {
        border-left: solid 12px #94C631; } }
    ul {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      li {
        margin: 0 7px;
        list-style-type: none;
        @include size(40px);
        line-height: 40px;
        @include font(21px,#000,0);
        font-weight: bold;
        border: solid 1px #707070;
        border-radius: 1px;
        text-align: center;
        cursor: pointer;
        @include t(.3);
        &.active,&:hover {
          background-color: #94C631;
          border-color: #94C631;
          color: #fff; } } } }

  .kc_row_inner {
    margin-bottom: 40px;
    margin-left: -8px;
    width: calc(100% + 16px);
    @include tablet {
      margin-bottom: 20px; }
    &>div {
      padding: 0 8px; } }

  .kc_single_image {
    @include tablet {
      margin-bottom: 20px; } }

  .kc-carousel_images {
    margin-bottom: 15px;
    margin-left: -8px;
    width: calc(100% + 16px); }

  .owl-item {
    padding: 0 8px; }


  .kc_text_block {
    h2 {
      padding-bottom: 15px;
      margin-bottom: 30px;
      @include font(24px,#000,2px);
      border-bottom: solid 1px #959595;
      @include phone {
        margin-bottom: 20px;
        padding-bottom: 10px; } }
    h3 {
      display: flex;
      margin-bottom: 25px;
      line-height: normal;
      @include font(22px,#000,1.5px);
      @include tablet {
        margin-bottom: 15px; }
      &:before {
        content: "";
        display: block;
        margin-right: 15px;
        margin-top: 10px;
        @include size(10px);
        min-width: 10px;
        background-color: rgba(#07B2E3,.4);
        @include tablet {
          margin-top: 8px; }
        @include mini {
          margin-top: 7px;
          margin-right: 10px; } } }
    h4 {
      margin-bottom: 20px;
      @include font(20px,#000,1px);
      font-weight: bold;
      @include phone {
        margin-bottom: 15px; } }
    img {
      display: block;
      width: 100%; }
    p {
      padding-bottom: 30px;
      @include font(18px,#000,1px);
      @include tablet {
        padding-bottom: 20px; }
      &+h3 {
        margin-top: 30px;
        @include tablet {
          margin-top: 20px; } } }
    h5 {
      padding-top: 30px;
      a {
        display: block;
        width: fit-content;
        padding: 10px 25px;
        text-decoration: none;
        margin: 0 auto;
        background-color: #94C631;
        border-radius: 2px;
        @include font(16px,#fff,1px); } } } }

#product-menu {
  padding-top: 60px;
  padding-right: 30px;
  position: relative;
  z-index: 10000;
  left: 0;
  @include t(.5);
  @include tablet {
    padding: 15px;
    position: fixed;
    top: 0;
    left: -250px;
    background-color: #fff;
    @include size(250px,100vh);
    &.active {
      left: 0; } }
  #toggle-menu {
    padding: 5px 0;
    position: absolute;
    left: 100%;
    top: 40%;
    @include size(25px,auto);
    text-align: center;
    line-height: normal;
    background-color: $clr-5;
    color: #fff;
    font-size: 14px;
    border-radius: 0 4px 4px 0;
    display: none;
    cursor: pointer;
    @include tablet {
      display: block; } }
  .wrapper {}
  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      a {
        display: block;
        @include font(18px,#000,1px);
        @include t(.3);
        text-decoration: none; } }

    &.list {
      &>li {
        margin-bottom: 15px;
        @include tablet {
          margin-bottom: 5px; }
        &>a {
          padding: 7px 15px;
          border: solid 2px rgba(#07B2E3,.4); }
        &:hover,&.active {
          &>a {
            background-color: rgba(#07B2E3,.4);
            border-color: transparent; } } } }
    &.sub-menu {
      display: none;
      padding-left: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      @include tablet {
        padding-bottom: 10px;
        padding-top: 10px; }
      &>li {
        &:hover>a,&.active>a {
          color: #07B2E3; } }
      li {
        a {
          display: flex;
          padding: 5px 0;
          &:before {
            display: block;
            content: "-";
            margin-right: 10px; } } } } } }

#product-menu-list {
  display: none; }

[id^="module-gallery"] {
  background-image: url(/wp-content/uploads/bg02.png);
  &>div {
    @include container;
    @include clearfix;
    overflow: hidden; }

  .kc_row_inner {
    margin-left: -10px;
    width: calc(100% + 20px);
    &>div {
      padding: 0 10px;
      border-bottom: solid 2px $clr-5;
      margin-bottom: 30px; }
    &:last-of-type {
      &>div {
        @include min(767px) {
          border-bottom-color: transparent; } } } }

  .kc-image-gallery {
    display: flex;
    flex-direction: column-reverse;
    h3 {
      padding: 15px;
      @include font(18px,#000,1.5px);
      text-align: center;
      font-weight: normal; } }

  .item-grid {
    display: none;
    box-shadow: -3px 3px 6px #00000029;
    &:first-of-type {
      display: block; }
    a,img {
      display: block;
      width: 100%; }
    &.grid-1 {
      width: 100%; } } }

[id^="module-contact"] {
  background-image: url(/wp-content/uploads/bg02.png);
  &>div {
    @include container;
    @include clearfix; }
  .kc_text_block {
    margin-bottom: 50px;
    @include tablet {
      margin-bottom: 30px; }
    h2 {
      margin-bottom: 30px;
      @include font(20px,#000,1.5px);
      line-height: normal;
      @include tablet {
        margin-bottom: 20px; } }
    p,p a {
      @include font(18px,#000,1px); } }

  .kc-google-maps {
    position: relative;
    padding-bottom: 40%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%); } } }
