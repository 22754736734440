// 引入
@import "./_include/_include.sass";


// container
.header-full-width .container {
  padding: 0; }

.container,
.header-full-width .container {
  @include container;
  @include clearfix; }

#content-inside {
  max-width: 100%;
  width: 100%; }

#content {
  min-height: 0;
  @include t(.5); }

#primary {
  padding: 0; }

.entry-content {
  margin-bottom: 0; }

// header
.header-full-width .container {
  position: static; }

.onepress-menu.onepress-menu-mobile li {
  @include mobile {
    border: none; } }

.main-navigation {
  .onepress-menu {
    // margin-right: -45px
    // default
    li {
      a {
        text-decoration: none;
        font-family: inherit;
        @include font(18px,#fff,1.2px); } }
    // 第一層
    &>li {
      line-height: 20px;
      &.menu-item-gtranslate {
        padding-top: 40px;
        display: flex;
        align-items: center;
        @include mobile {
          justify-content: center;
          padding-top: 20px;
          padding-bottom: 20px; } }
      &.icon {
        display: none; }
      &>a {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 35px;
        padding-left: 35px;
        text-align: center;
        text-transform: none;
        background-image: url('/wp-content/uploads/arrow-down-sign-to-navigate.svg');
        background-size: 10px auto;
        background-position: 50% calc(100% - 15px);
        background-repeat: no-repeat;
        @include mobile {
          background: none; }
        @include tablet {
          background: none; }
        &:after {
          content: attr(title);
          display: block;
          @include font(14px,#fff,0.2px);
          font-weight: normal; } }

      // 第二層
      ul.sub-menu {
        border: none;
        // box-shadow: 3px 3px 6px #eee
        width: 100%;
        min-width: 180px;
        left: 50%;
        transform: translateX(-50%);
        @include mobile {
          transform: none; }
        &>li {
          &>a {
            display: block;
            border: none;
            text-align: center;
            @include font(16px,#333,0);
            &:hover {
              background-color: #00B9EF;
              color: #fff; } } } } }
    // 手機版
    @include mobile {
      &.onepress-menu-mobile {
        padding: 20px 0;
        margin-right: 0;
        border: none;
        background-color: rgba($clr-4,0.9);
        // mobile default
        li {
          background-color: transparent;
          a {
            padding: 10px 30px;
            &:hover {
              color: $clr-2; } } }
        // 第一層
        &>li {
          &>a {
 }            // display: none
          // 第二層
          ul.sub-menu {
            background-color: #666;
            box-shadow: none;
            padding: 10px 0;
            left: 0;
            width: 100%;
            &>li {
              background: transparent;
              &>a {
                background: transparent;
                color: #fff; } } } }
        .nav-toggle-subarrow {
          padding: 10px 30px;
          @include mobile {
            border: none;
            width: 100%;
            height: 60px;
            i {
              float: right;
              display: none; } } } } } } }

#nav-toggle {
  margin: 0;
  position: absolute;
  top: 25px;
  right: 30px;
  z-index: 1000;
  @include size(35px);
  @include tablet {
    top: 12px; }
  span {
    background-color: #fff;
    &:before,&:after {
      transform-origin: 50% 50%;
      background-color: #fff; } }
  &.nav-is-visible {
    background-color: rgba(#fff,.3);
    border-radius: 50%;
    span {
      &:before,&:after {
        top: 0;
        left: 0; }
      &:before {
        transform: rotate(45deg); }
      &:after {
        transform: rotate(-45deg); } } } }

// footer
.footer-widgets,.site-footer {
  background: transparent; }

.site-footer {
  background-image: url(/wp-content/uploads/img_footer.png);
  background-size: cover;
  position: relative;
  .container {
    max-width: 100%;
    width: 100%;
    padding: 0; }
  &:before {
    content: "";
    display: block;
    @include size(100%);
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#106B79,.7); }
  p.copyright {
    text-align: center;
    // background-color: #333
    padding: 30px 0;
    @include font(16px,$clr-2,1.2px);
    position: relative;
    z-index: 2;
    a {
      margin-left: 30px;
      @include font(16px,$clr-2,1.2px); } } }

#footer-widgets {
  padding: 30px 0 0;
  // background-image: url('')
  .info {
    margin-bottom: 30px;
    ul {
      display: flex;
      margin: 0;
      padding-left: 5vw;
      @include mobile {
        display: block;
        padding-left: 0; }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10vw 0 0;
        @include mobile {
          margin: 0 0 10px; }
        &:first-of-type {
          margin-right: 100px;
          @include mobile {
            margin-right: 0; } }
        span {
          font-weight: bold; }
        span,p,a {
          display: block;
          @include font(16px,#fff,1.6px);
          white-space: nowrap;
          font-weight: bold; }
        a.logo {
          display: block;
          @include size(150px,auto);
          @include mobile {
            margin-bottom: 30px; }
          img {
            display: block;
            width: 100%; } } } } }
  .footer-widget.widget_nav_menu {
    padding: 0;
    margin: 0;
    .menu {
      display: flex;
      justify-content: space-between;
      margin: 0 0 0 -1px;
      border-top: solid 1px $clr-3;
      border-bottom: solid 1px $clr-3;
      @include tablet {
        display: block;
        border-bottom: none; }
      // default
      li {
        a {
          display: block;
          text-decoration: none;
          &:hover {
            opacity: 0.6; } } }
      // 第一層
      &>li {
        // margin-right: 50px
        margin: 0;
        padding-bottom: 20px;
        flex: 1;
        border-left: solid 1px $clr-3;
        &.icon {
          display: none; }
        @include tablet {
          margin-right: 0;
          padding-bottom: 0;
          border-bottom: solid 1px $clr-3; }
        &.menu-item-has-children {
          &>a {
            @include tablet {
              // display: flex
 }              // justify-content: space-between
            // &:after
            //   content: "\f107"
            //   font-family: 'FontAwesome'
            //   font-style: inherit
            //   -webkit-font-smoothing: antialiased
            //   -moz-osx-font-smoothing: grayscale
            //   display: inline-block
            //   text-rendering: auto
            //   speak: none
            //   font-weight: 400
            //   display: none
            //   +tablet
 }            //     display: block
          &.open {
            // &>a:after
            //   content: "\f106"
            .sub-menu {
              display: block; } } }
        &:last-of-type {
          margin-right: 0; }
        &>a {
          display: block;
          text-align: center;
          font-weight: bold;
          padding: 0px;
          margin-top: 40px;
          margin-bottom: 10px;
          // border-bottom: solid 1px #333
          @include font(16px,#fff,1.6px);
          @include tablet {
            margin: 0;
            padding: 10px 0;
            border-bottom-color: #ccc; } }
        // 第二層
        ul.sub-menu {
          li {
            margin: 0;
            a {
              padding: 5px 0;
              text-align: center;
              @include font(16px,#fff,1.6px); } }
          @include tablet {
            display: none;
            padding: 0px 0 10px;
            margin-top: -5px; } } } } } }

// kim
.kc-container,
.kc_column, .kc_column_inner {
  padding: 0;
  min-height: 0; }

.kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner,
.kc_row.kc_row_inner {
  width: 100%;
  margin: 0; }

#welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  &>div {
    @include container; }

  .kc_text_block {
    text-align: center; } }


.site-content {
  border-top: none; }


// 右側購物車
.xoo-wsc-product.xoo-wsc-is-child {
  padding-top: 10px; }

.xoo-wsc-product {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  .xoo-wsc-img-col {
    align-self: start; }
  .xoo-wsc-qty-price {
    font-size: 14px;
    line-height: 20px;
    * {
      line-height: normal;
      font-size: 14px; }
    span:nth-of-type(3) {
      color: red;
      .woocommerce-Price-amount {
        color: red; } } }
  &.xoo-wsc-is-parent {
    .xoo-wsc-img-col {
      display: none; }
    .xoo-wsc-sum-col {
      padding-left: 0; }
    .xoo-wsc-qty-price {
      display: none; } }

  &.xoo-wsc-is-child {
    img {
      max-width: 100%; } } }

#header-section {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%,auto);
  @include tablet {
    position: relative; } }

// 頭部
.site-header {
  padding: 0;
  background-color: rgba($clr-1,.6);
  @include tablet {
    background-color: rgba($clr-1,1); }
  .container {
    width: 100%;
    max-width: 1300px;
    padding: 0 30px; } }

.site-branding {
  padding: 25px 0 15px;
  width: 200px;
  @include mobile {
    width: 150px; }
  @include tablet {
    padding: 15px 0;
    width: 100px; }
  a {
    display: block;
    img {
      display: block;
      @include size(100%,auto); } } }


.kc_text_block.title {
  margin-bottom: 45px;
  h2 {
    margin-bottom: 10px;
    @include font(26px,$clr-4,1.9px);
    &:after,&:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin: 0 20px;
      @include size(100px,1px);
      background-color: $clr-4;
      @include tablet {
        width: 50px; } } }
  h3 {
    @include font(16px,$clr-4,1.2px); }
  &.white {
    * {
      color: #fff; }
    h2 {
      &:after,&:before {
        background-color: #f0f0f0; } } } }


#gtranslate_selector {
  // display: none
  // position: absolute
  // top: 30px
  // right: 5%
 }  // z-index: 10000

.breadcrumbs {
  border: none;
  background-color: #F5F5F5;
  .container {
    &>span {
      margin-right: 0;
      span {
        font-size: 14px;
        color: #000;
        font-weight: normal;
        @include mini {
          font-size: 12px; }
        &:after {
          margin: 0 5px;
          content: "/"; } }
      &:last-of-type {
        span:after {
          display: none; } }
      &>a {
        text-decoration: none;
        &:before,&:after {
          display: none; }
        &.home {
          span {
            font-size: 0;
            &:before {
              content: "首頁";
              font-size: 14px;
              @include mini {
                font-size: 12px; } }
            &:after {
              font-size: 14px;
              @include mini {
                font-size: 12px; } } } } } } } }

div.pp_pic_holder.pp_pic_holder {
  top: 50%!important;
  left: 50%!important;
  transform: translate(-50%,-50%);
  position: fixed;
  width: auto!important;
  max-width: 900px;
  img,.pp_content,.pp_hoverContainer,.pp_details {
    display: block;
    width: 100%!important;
    height: auto!important;
    max-height: 80vh;
    max-width: 100%;
    object-fit: contain; }
  a {
    text-decoration: none; } }

.pp_content_container.pp_content_container {
  .pp_left,.pp_right {
    padding-left: 0;
    padding-right: 0; } }
